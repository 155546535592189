import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import {
  Avatar,
  Grid,
  Box,
  Container,
  Tooltip,
  Typography,
  Badge,
  Link,
  IconButton,
  Modal,
  Backdrop,
  Fade,
  Card,
  CardContent,
} from "@material-ui/core"
import LinkedInIcon from "@material-ui/icons/LinkedIn"
import ReactMarkdown from "react-markdown"
import PageviewOutlinedIcon from "@material-ui/icons/PageviewOutlined"
import HighlightOffIcon from "@material-ui/icons/HighlightOff"

import background from "../images/bg/lines.png"
import Layout from "../components/layout"
import Heading from "../components/heading"
// import about from "../images/about/about.svg"
import about from "../images/about/business.jpg"
import price from "../images/about/price.svg"
import endtoend from "../images/about/endtoend.svg"
import speed from "../images/about/speed.svg"
import aftersales from "../images/about/aftersales.svg"

const TeamModal = ({ open, handleClose, item }) => {
  const classes = useStyles()

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Container maxWidth="md">
          <div className={classes.paper}>
            <Box
              position="absolute"
              top="20px"
              right="20px"
              onClick={handleClose}
            >
              <HighlightOffIcon color="secondary" fontSize="large" />
            </Box>
            <Grid container>
              <Grid item md={4}>
                <Badge
                  overlap="circle"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  badgeContent={
                    item.linkedin && (
                      <Link target="_blank" href={item.linkedin}>
                        <Box
                          bgcolor="#fff"
                          borderColor="transparent"
                          borderRadius={5}
                        >
                          <LinkedInIcon htmlColor="#0077b5" fontSize="large" />
                        </Box>
                      </Link>
                    )
                  }
                >
                  <Avatar
                    className={classes.img}
                    alt={item.name}
                    src={item.image?.publicURL}
                  />
                </Badge>
                <Box pt={3} pb={1} align="left">
                  <Typography
                    variant="h5"
                    gutterBottom
                    color="primary"
                    align="left"
                    style={{ textTransform: "capitalize" }}
                  >
                    {item.name}
                  </Typography>
                  <Typography variant="subtitle" gutterBottom align="left">
                    {item.designation}
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={8}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  className={classes.spacing}
                  px={4}
                >
                  <Typography
                    variant="subtitle1"
                    component="p"
                    align="center"
                    gutterBottom
                  >
                    {item.description}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </div>
        </Container>
      </Fade>
    </Modal>
  )
}

const Teamsss = ({ teams }) => {
  const classes = useStyles()

  return (
    <>
      {teams.Team.map(item => (
        <Grid
          item
          xs={12}
          md={3}
          container
          direction="column"
          alignItems="center"
        >
          <Card elevation={10}>
            <CardContent>
              <Badge
                overlap="circle"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                badgeContent={
                  item.linkedin && (
                    <Link target="_blank" href={item.linkedin}>
                      <Box
                        bgcolor="#fff"
                        borderColor="transparent"
                        borderRadius={5}
                      >
                        <LinkedInIcon htmlColor="#0077b5" fontSize="large" />
                      </Box>
                    </Link>
                  )
                }
              >
                <Avatar
                  variant="square"
                  className={classes.img}
                  alt={item.name}
                  src={item.image?.publicURL}
                />
              </Badge>

              <div>
                <Box pt={3} pb={1} align="center">
                  <Typography
                    variant="h5"
                    gutterBottom
                    color="primary"
                    align="center"
                    style={{ textTransform: "capitalize" }}
                  >
                    {item.name}
                  </Typography>
                  <Typography variant="subtitle" gutterBottom align="center">
                    {item.designation}
                  </Typography>
                </Box>
              </div>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </>
  )
}

function Teams({ teams }) {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [selectedItem, setSelectedItem] = React.useState()

  const handleOpen = item => {
    setSelectedItem(item)
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      {teams.Team.map(item => (
        <Grid
          item
          xs={12}
          md={4}
          container
          direction="column"
          alignItems="center"
        >
          {open && item === selectedItem && (
            <TeamModal
              open={open}
              item={selectedItem}
              handleClose={handleClose}
            />
          )}
          <Badge
            overlap="circle"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            // badgeContent={
            //   <Box bgcolor="#fff" borderColor="transparent" borderRadius="50%">
            //     <IconButton
            //       size="small"
            //       onClick={() => {
            //         handleOpen(item)
            //       }}
            //     >
            //       <PageviewOutlinedIcon color="primary" />
            //     </IconButton>
            //   </Box>
            // }
            // badgeContent={
            //   item.linkedin && (
            //     <Link target="_blank" href={item.linkedin}>
            //       <Box
            //         bgcolor="#fff"
            //         borderColor="transparent"
            //         borderRadius={5}
            //       >
            //         <LinkedInIcon htmlColor="#0077b5" fontSize="large" />
            //       </Box>
            //     </Link>
            //   )
            // }
          >
            <Avatar
              className={classes.img}
              alt={item.name}
              src={item.image?.publicURL}
            />
          </Badge>
          <Box pt={3} pb={1} align="center">
            <Typography
              variant="h5"
              gutterBottom
              color="primary"
              align="center"
              style={{ textTransform: "capitalize" }}
            >
              {item.name}
            </Typography>
            <Typography variant="subtitle" gutterBottom align="center">
              {item.designation}
            </Typography>

            {item.description && (
              <div style={{ marginTop: 10 }}>
                <span className="btnn" onClick={() => handleOpen(item)}>
                  {/* DONOT CHANGE ANYTHING HERE */}
                  <a></a>
                </span>
              </div>
            )}
          </Box>
          {/* {item.description && (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              className={classes.spacing}
              pb={4}
            >
              <Typography
                variant="subtitle1"
                component="p"
                align="center"
                gutterBottom
              >
                {item.description}
              </Typography>
            </Box>
          )} */}
        </Grid>
      ))}
    </>
  )
}

function Partners({ partners }) {
  return (
    <>
      {partners.Companies.map(item => (
        <>
          <Grid item>
            <Tooltip title={item.name}>
              <img src={item.logo?.publicURL} alt={item.name} height="80px" />
            </Tooltip>
          </Grid>
        </>
      ))}
    </>
  )
}

function About({ children }) {
  const classes = useStyles()

  const query = useStaticQuery(graphql`
    query {
      seo: strapiSeo {
        aboutPage {
          pagetitle
          description
          keywords
        }
      }
      about: allStrapiAbout {
        edges {
          node {
            heading
            content
          }
        }
      }
      team: allStrapiTeam {
        edges {
          node {
            Team {
              description
              designation
              image {
                publicURL
              }
              linkedin
              name
            }
          }
        }
      }
      partner: allStrapiPartners {
        edges {
          node {
            Companies {
              logo {
                publicURL
              }
              name
            }
          }
        }
      }
    }
  `)

  return (
    <Layout
      siteTitle={query.seo.aboutPage.pagetitle ?? "About"}
      description={query.seo.aboutPage.description ?? ""}
      keywords={query.seo.aboutPage.keywords ?? ""}
    >
      <Heading
        title="Our Mission"
        content={
          <Box fontSize={22}>
            Simplifying{" "}
            <Typography color="error" component="span">
              <Box fontSize={22} component="span">
                <b>Data-Based Decision Making</b>
              </Box>
            </Typography>{" "}
            using Technology!
          </Box>
        }
      />
      <Box py={10}>
        <Grid container justify="center">
          <Grid
            item
            xs={12}
            md={6}
            container
            justify="center"
            alignItems="center"
          >
            <Grid item xs={8} md={10}>
              <Typography variant="h4" gutterBottom color="primary">
                <b>{query.about.edges[0].node.heading}</b>
              </Typography>
              <Typography variant="subtitle1" gutterBottom align="justify">
                <ReactMarkdown
                  children={query.about.edges[0].node.content}
                  transformImageUri={uri =>
                    uri.startsWith("http")
                      ? uri
                      : `${process.env.GATSBY_IMAGE_BASE_URL}${uri}`
                  }
                  className="dynamicContent"
                  escapeHtml={true}
                />
              </Typography>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={5}
            container
            justify="center"
            alignItems="center"
          >
            <Box py={5} mx={5}>
              <img
                src={about}
                alt="About"
                Height="280px"
                style={{ filter: "hue-rotate(217deg)" }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box py={10} className={classes.background}>
        <Grid container justify="center">
          <Grid item xs={12} container justify="center">
            <Box py={4} textAlign="center">
              <Typography variant="h4" gutterBottom color="secondary">
                <b>What makes us the best</b>
              </Typography>
            </Box>
          </Grid>
          <Grid item md={4} xs={6} container justify="center">
            <Box py={4}>
              <Box display="flex" justifyContent="center">
                <Typography variant="h2" color="secondary">
                  <b>20</b>
                </Typography>
                <Typography variant="h4" color="secondary">
                  <b>+</b>
                </Typography>
              </Box>
              <Typography variant="subtitle1" color="secondary" align="center">
                YEARS OF INDUSTRY EXPERIENCE
              </Typography>
            </Box>
          </Grid>
          <Grid item md={4} xs={6} container justify="center">
            <Box py={4}>
              <Box display="flex" justifyContent="center" pb={1}>
                <img src={endtoend} height="75px" width="75px" />
              </Box>
              <Typography variant="subtitle1" color="secondary" align="center">
                END TO END CAPABILITIES
              </Typography>
            </Box>
          </Grid>
          <Grid item md={4} xs={6} container justify="center">
            <Box py={4}>
              <Box display="flex" justifyContent="center" pb={1}>
                <img src={speed} height="75px" width="75px" />
              </Box>
              <Typography variant="subtitle1" color="secondary" align="center">
                SPEED OF EXECUTION
              </Typography>
            </Box>
          </Grid>
          <Grid item md={4} xs={6} container justify="center">
            <Box py={4}>
              <Box display="flex" justifyContent="center" pb={1}>
                <img src={aftersales} height="75px" width="75px" />
              </Box>
              <Typography variant="subtitle1" color="secondary" align="center">
                AFTER SALES SUPPORT
              </Typography>
            </Box>
          </Grid>
          <Grid item md={4} xs={6} container justify="center">
            <Box py={4}>
              <Box display="flex" justifyContent="center" pb={1}>
                <img src={price} height="75px" width="75px" />
              </Box>
              <Typography variant="subtitle1" color="secondary" align="center">
                FLEXIBLE PRICING
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box py={10}>
        <Box textAlign="center">
          <Typography variant="h4" gutterBottom color="primary" align="center">
            <b>Meet Our Team</b>
          </Typography>
        </Box>

        <Container>
          <Box py={5}>
            <Grid container justify="space-evenly">
              {query.team.edges.map(teams => (
                <Teams teams={teams.node} />
              ))}
            </Grid>
          </Box>
        </Container>
      </Box>
      <Box pb={10}>
        <Container>
          <Typography variant="h4" color="primary" align="center" gutterBottom>
            <Box style={{ fontVariant: "small-caps" }}>
              <b>Our Partners</b>
            </Box>
          </Typography>
          <Box pt={5}>
            <Grid container spacing={3} justify="space-evenly">
              {query.partner.edges.map(partners => (
                <>
                  <Partners partners={partners.node} />
                </>
              ))}
            </Grid>
          </Box>
        </Container>
      </Box>
    </Layout>
  )
}

const useStyles = makeStyles(theme => ({
  background: {
    position: "relative",
    "&::after": {
      content: "''",
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      position: "absolute",
      zIndex: -1,
      // backgroundColor:"#031242"
      backgroundImage: `url(${background})`,
      backgroundPosition: "center center",
    },
  },
  spacing: {
    [theme.breakpoints.down("md")]: {
      paddingRight: "25px",
      paddingLeft: "25px",
    },
    [theme.breakpoints.up("md")]: {
      paddingRight: "0px",
      paddingLeft: "0px",
    },
  },
  img: {
    width: theme.spacing(20),
    height: theme.spacing(20),
    "&>img": {
      // width: "auto",
      objectPosition: "top",
      objectFit: "contain",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

export default About
